import { useMedia } from "@/composables/useMedia.js";
import { computed } from "vue";

/**
 * Returns the current device class based on the width of the window.
 * @returns {ComputedRef} A computed ref containing the current device class ("mobile", "laptop", or "desktop").
 */
export const useScreenSize = () => {
	const mobileSize = 1100;
	const laptopSize = 1500;
	const desktopSize = 1500;

	const isMobile = useMedia(`(max-width: ${mobileSize}px)`);
	const isLaptop = useMedia(`(max-width: ${laptopSize}px)`);
	const isDesktop = useMedia(`(min-width: ${desktopSize}px)`);

	return computed({
		get() {
			if (isMobile.value) {
				return "mobile";
			}
			if (isLaptop.value) {
				return "laptop";
			}
			if (isDesktop.value) {
				return "desktop";
			}
		},
	});
};
