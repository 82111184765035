/**
    @function
    @returns {object} An object containing the URLs for different pages within the app.
    @property {string} appLinkHome - The URL for the home page.
    @property {string} appLinkLangFR - The URL for the French language version of the app.
    @property {string} appLinkLangEN - The URL for the English language version of the app.
    @property {string} appLinkContact - The URL for the contact page.
    @property {string} appLinkAccount - The URL for the account page.
    @property {string} appLinkCart - The URL for the cart page.
    @property {string} appLinkShop - The URL for the shop page.
    @property {string} appLinkFacebook - The URL for the app's Facebook page.
    @property {string} appLinkInstagram - The URL for the app's Instagram page.
    @property {string} appLinkYoutube - The URL for the app's YouTube page.
    */
export const useAppLink = () => {
	const appLinkHome = "/tableau-de-bord";
	const appLinkLangFR = "/";
	const appLinkLangEN = "/";
	const appLinkContact = "/contact";
	const appLinkAccount = "/";
	const appLinkCart = "/panier";
	const appLinkShop = "/";
	const appLinkFacebook = "/";
	const appLinkInstagram = "/";
	const appLinkYoutube = "/";

	return {
		appLinkHome,
		appLinkLangFR,
		appLinkLangEN,
		appLinkContact,
		appLinkAccount,
		appLinkCart,
		appLinkShop,
		appLinkFacebook,
		appLinkInstagram,
		appLinkYoutube,
	};
};
