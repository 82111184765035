import { useEventListener } from "./useEventListener.js";
import { ref } from "vue";

/**
    @function useMedia
    @param {string} query - A CSS media query to test for.
    @returns {Ref} A ref containing a boolean indicating whether the media query matches the current viewport.
    @example
		const isSmallScreen = useMedia("(max-width: 600px)");
		watch(isSmallScreen, (matches) => {
			console.log(matches ? "Viewport is small" : "Viewport is large");
		});
**/
export const useMedia = (query) => {
	const mediaQuery = window.matchMedia(query);
	const matches = ref(mediaQuery.matches);

	useEventListener(mediaQuery, "change", (event) => {
		matches.value = event.matches;
	});

	return matches;
};
