<template>
  <nav class="sidebar">
    <ul class="nav flex-column gap1">
      <div id="div_logo">
        <nuxt-link :to="appLinkHome" :title="appStore.currentEtablissement.siteName">
          <img id="navbar_logo" src="/img/logos/logo-ferron.png"
               placeholder="/img/logos/logo-ferron.png" />
        </nuxt-link>
      </div>
      <hr>
    </ul>
    <ul class="nav flex-column gap2 mt-2">
      <div v-for="(datas, index) in data" :key="index">
        <!-- Si il comporte des sous menu -->
        <li v-if="datas.subCategories.length > 0" class="accordion"
          :class="{ active: (route.fullPath.includes(datas.link)) }">
          <button :id="'collapse_' + datas.idCategory" class="accordion-button" data-bs-toggle="collapse"
            :data-bs-target="'#collapseContent_' + datas.idCategory">
            <img class="img" :src="datas.imageCategory" :alt="datas.nameCategory">
            {{ datas.nameCategory }}
          </button>
          <div :id="'collapseContent_' + datas.idCategory" :class="['collapse', { show: datas.showSubMenu }, 'mt-3']">
            <ul class="list-group">
              <li v-for="subCategory in datas.subCategories" :key="subCategory.idSubCategory">
                <NuxtLink
                  :class="{ isDisabled: subCategory.disabled, activeSubCategory: route.fullPath === subCategory.linkSubCategory }"
                  :href="subCategory.linkSubCategory" class="list-group-item"><i class="fas fa-chevron-right"></i> {{
                    subCategory.nameSubCategory
                  }}</NuxtLink>
              </li>
            </ul>
          </div>
        </li>
        <!-- si il ne comporte pas de sous menu -->
        <li v-else class="categorie" :class="{ active: (route.fullPath).includes(datas.link) }">
          <nuxt-link :class="{ isDisabled: datas.disabled }" :to="(datas.nameCategory === 'Catalogue') ? '#' : datas.link"
            @click="(datas.nameCategory === 'Catalogue') ? openModalSearchVehicle() : ''"
            @click.prevent="handleClick(datas)">
            <img class="img" :src="datas.imageCategory" :alt="datas.nameCategory">
            {{ datas.nameCategory }}
          </nuxt-link>
        </li>
      </div>
    </ul>
    <ul class="nav flex-column gap2 mt-2 logout">
      <li class="categorie">
        <NuxtLink to="#" class="d-flex column-gap-2" @click="logout()">
          <i class="fa-solid fa-right-from-bracket"></i>
          Déconnexion
        </NuxtLink>
      </li>
    </ul>
  </nav>

  <div class="modal fade" id="modalSearchVehicule" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5>Cherchez votre vehicule</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-target="#modalSearchVehicule"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="box-search-immat">
            <div class="tabMenu d-flex">
              <div class="w-50 tabMenuItem d-flex align-items-center column-gap-3"
                :class="{ activeTabMenuItem: (tabMenuItemActive === 0) }" @click="activeTabMenuItem(0)">
                <div class="icon-tab">
                  <i class="fa-solid fa-list-ol"></i>
                </div>
                <span>Par Immatriculation</span>
              </div>
              <div class="w-50 tabMenuItem d-flex align-items-center column-gap-3"
                :class="{ activeTabMenuItem: (tabMenuItemActive === 1) }" @click="activeTabMenuItem(1)">
                <div class="icon-tab">
                  <i class="fa-solid fa-car"></i>
                </div>
                <span>Par Vehicule</span>
              </div>
            </div>
            <div v-if="(tabMenuItemActive === 0)" class="searchByImmat d-flex flex-column row-gap-3">
              <div class="box-immat">
                <p>Par plaque</p>
                <div class="input-with-flag">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="fi fi-fr"></span>
                    </button>
                    <ul class="dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><span class="fi fi-es"></span></li>
                      <li><span class="fi fi-de"></span></li>
                      <li><span class="fi fi-gr"></span></li>
                    </ul>
                  </div>
                  <input type="text" class="form-control input-vehicule" placeholder="AA-123-AA" aria-label="Rechercher"
                    aria-describedby="basic-addon2" v-model="immat" @keydown.enter="getCarcode()">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="History" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa-solid fa-clock-rotate-left" data-v-ef483475=""></i>
                    </button>
                    <ul class="dropdown-menu custom-dropdown-menu" aria-labelledby="History">
                      <li v-for="vehicle in historyVehicleList" @click="redirectToCatalog(vehicle.KType, vehicle.plateNum)">
                        <span class="historyVehicleText">{{ vehicle.plateNum }}</span>
                      </li>
                    </ul>
                  </div>
                  <nuxt-link to="#" class="btn btn-search" type="button" @click="getCarcode()">
                    <i class="fas fa-search search-icon"></i>
                  </nuxt-link>
                </div>
              </div>
              <div class="box-vin">
                <p>Par VIN</p>
                <div class="vin">
                  <input type="text" class="form-control" placeholder="VFXXXXXXXXXXXXX" aria-label="Rechercher"
                    aria-describedby="basic-addon2">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="History" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa-solid fa-clock-rotate-left" data-v-ef483475=""></i>
                    </button>
                    <ul class="dropdown-menu custom-dropdown-menu" aria-labelledby="History">
                      <li>AA-123-AA</li>
                      <li>AA-123-AA</li>
                      <li>AA-123-AA</li>
                    </ul>
                  </div>
                  <button class="btn btn-search" type="button">
                    <i class="fas fa-search search-icon"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="(tabMenuItemActive === 1)" class="searchByVehicle d-flex flex-column row-gap-3">
              <p>Par véhicule</p>
              <div class="box-immat d-flex justify-content-center">
                <div class="searchVehicleSelect w-92 d-flex flex-column row-gap-3">
                  <select class="form-select" aria-label="Default select example" @change="getModelOfBrands">
                    <option value="" disabled selected>Saisir le nom de la marque</option>
                    <option :value="brands.id" v-for="brands in allBrands">{{ brands.name }}</option>
                  </select>
                  <select class="form-select" aria-label="Default select example" @change="getTypeOfModel">
                    <option value="" disabled selected>Saisir le modèle</option>
                    <option :value="model.id" v-for="model in allModel">{{ model.name }}</option>
                  </select>
                  <select class="form-select" aria-label="Default select example" @change="getSelectedVehicle">
                    <option value="" disabled selected>Saisir le type</option>
                    <option :value="type.linkageTargetId" v-for="type in allType">{{ type.description }} {{ type.driveType }}</option>
                  </select>
                </div>
                <div>
                  <button class="btn btn-search btnSearchByVehicle" type="button" @click="searchVehicleByBrandsModelType">
                    <i class="fas fa-search search-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade modalValidVehicle" id="modalValidVehicle" tabindex="-1" aria-labelledby="modalValidVehicleLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="modalValidVehicleLabel">Validez votre véhicule</h1>
          <button type="button" class="btn-close" @click="closeModalValidVehicule()"></button>
        </div>
        <div class="modal-body">
          <div class="box-immat mb-4 d-flex justify-content-center">
            <div class="input-with-flag">
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="fi fi-fr"></span>
                </button>
                <ul class="dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="fi fi-es"></span></li>
                  <li><span class="fi fi-de"></span></li>
                  <li><span class="fi fi-gr"></span></li>
                </ul>
              </div>
              <input type="text" class="form-control input-vehicule" placeholder="AA-123-AA" aria-label="Rechercher"
                aria-describedby="basic-addon2" v-model="immat">
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="History" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <!--                                            <img src="/img/icons/history.png" alt="history">-->
                  <i class="fa-solid fa-clock-rotate-left"></i>
                </button>
                <ul class="dropdown-menu custom-dropdown-menu" aria-labelledby="History">
                  <li>AA-123-AA</li>
                  <li>AA-123-AA</li>
                  <li>AA-123-AA</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table tableImmatVehicle">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Numéro TecDoc</th>
                  <th>Code moteur</th>
                  <th>Capacité</th>
                  <th>Alimentation</th>
                  <th>Année-modèle</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listVehicle">
                  <td class="type">{{ item.carName }}</td>
                  <td>{{ item.tecDocModNr }}</td>
                  <td>{{ item.engineCode }}</td>
                  <td>{{ item.engineCapacity }}</td>
                  <td>{{ item.power }} kW</td>
                  <td></td>
                  <td class="w-10">
                    <NuxtLink to="#" class="btn-linear d-flex column-gap-2 justify-content-between align-items-center"
                      @click="getSingleCarCode(item.KType, item.numberPlate)">
                      <span>OK</span>
                      <i class="fa-solid fa-chevron-right"></i>
                    </NuxtLink>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAppStore } from "@/store/appStore.js";
import { useAppLink } from "@/composables/useAppLink.js";
import { useScreenSize } from "@/composables/useScreenSize.js";
import axios from "axios";

const route = useRoute();

const appStore = useAppStore();
const currentDevice = ref("");
const chooseDevice = "mobile";
const { appLinkHome, appLinkContact, appLinkAccount } = useAppLink();
const tabMenuItemActive = ref(0)
const immat = ref("")
const listVehicle = ref([])
const listVehicleGeneric = ref([])
const modalValidVehicle = ref({})
const modalSearchVehicle = ref({})
const historyVehicleList = ref([])
const APP_INFO_CAR = useCookie('APP_INFO_CAR')
const APP_CAR_PLATE_DATAS = useCookie('APP_CAR_PLATE_DATAS')
const APP_CAR_GENERIC_DATAS = useCookie('APP_CAR_GENERIC_DATAS')
const allBrands = ref([])
const allModel = ref([])
const allType = ref([])
const levl1_id = ref('')
const levl2_id = ref('')
const vehicleSelectedBrandModelType = ref({})

onMounted(() => {
  currentDevice.value = useScreenSize();
  setTimeout(() => {
    modalValidVehicle.value = new bootstrap.Modal(document.getElementById("modalValidVehicle"))
    modalSearchVehicle.value = new bootstrap.Modal(document.getElementById("modalSearchVehicule"))
  }, 1000)
  getAllBrandsForSearch()
});

const data = appStore.currentEtablissement.navbarApp;

//Empecher la redirection si l'élément contient la class isDisabled
const handleClick = (datas) => {
  if (datas.disabled) {
    // Empêcher l'action de redirection si l'élément a la classe "disabled"
    return;
  }

  // Effectuer l'action de redirection normalement
  // window.location.href = datas.link;
};

const activeTabMenuItem = (index) => {
  tabMenuItemActive.value = index
}

const getCarcode = async () => {
  const getCarPlateRequest = await $fetch(`/api/getCarCode?pl=${immat.value}&userEmail=${appStore.userConnected.userEmail}&userId=${appStore.userConnected.userId}`, {
    headers: {
      'User-Authorization' : `${appStore.auth.userToken}`
    }
  })
  listVehicle.value = getCarPlateRequest.carPlateDatas
  listVehicleGeneric.value = getCarPlateRequest.carGenericDatas

  modalSearchVehicle.value.hide()
  modalValidVehicle.value.show()
}

const getSingleCarCode = async (Ktype, numberPlate) => {
  // carPlateDatas du vehicule sélectionné
  const singleVehicle = listVehicle.value.filter((elm) => {
    return (elm.KType == Ktype) && (elm.numberPlate == numberPlate)
  })[0]

  const genericVehicle = listVehicleGeneric.value.filter((elm) => {
    return (elm.KType == Ktype)
  })[0]

  appStore.infoCar.actualKType = Ktype
  appStore.infoCar.actualImmat = numberPlate
  appStore.infoCar.carGenericDatas = genericVehicle
  appStore.infoCar.carPlateDatas = singleVehicle
  appStore.infoCar.vin = singleVehicle.vin
  appStore.infoCar.lastKType = (!appStore.infoCar.lastKType) ? Ktype : appStore.infoCar.lastKType
  appStore.infoCar.lastImmat = (!appStore.infoCar.lastImmat) ? numberPlate : appStore.infoCar.lastImmat

  // Send info car to the cookies
  APP_INFO_CAR.value = {
    actualKType: appStore.infoCar.actualKType,
    actualImmat: appStore.infoCar.actualImmat,
    actualVin: appStore.infoCar.actualVin,
    lastKType: appStore.infoCar.lastKType,
    lastImmat: appStore.infoCar.lastImmat
  }

  // Send info carPlatesDatas & carGenericDatas
  APP_CAR_PLATE_DATAS.value =  appStore.infoCar.carPlateDatas
  APP_CAR_GENERIC_DATAS.value = appStore.infoCar.carGenericDatas

  modalValidVehicle.value.hide()
  navigateTo("/catalogue/mon-vehicule/default")
}

const openModalSearchVehicle = async () => {
  if (APP_INFO_CAR.value?.actualImmat || APP_INFO_CAR.value?.actualKType) {
    navigateTo("/catalogue/mon-vehicule/default")
  } else {
    historyVehicleList.value = (await $fetch(`/api/getPlateUserHistory?top=3&userEmail=${appStore.userConnected.userEmail}&userId=${appStore.userConnected.userId}`, {
      headers: {
        'User-Authorization' : `${appStore.auth.userToken}`
      }
    })).history
    modalSearchVehicle.value.show()
  }
}

const closeModalValidVehicule = () => {
  modalValidVehicle.value.hide()
}

const logout = () => {
  const APP_AUTH_USER_TOKEN = useCookie('APP_AUTH_USER_TOKEN')
  APP_AUTH_USER_TOKEN.value = ''
}

const redirectToCatalog = async (Ktype, numberPlate) => {
  APP_INFO_CAR.value = {
    actualKType: Ktype,
    actualImmat: numberPlate,
    actualVin: "",
    lastKType: "",
    lastImmat: ""
  }
  let getOneVehicleRequest = await $fetch(`/api/getOneVehicle?Ktype=${Ktype}&immat=${numberPlate}&userEmail=${appStore.userConnected.userEmail}&userId=${appStore.userConnected.userId}`, {
    headers: {
      'User-Authorization' : `${appStore.auth.userToken}`
    }
  })
  APP_CAR_PLATE_DATAS.value = getOneVehicleRequest.carPlateDatas[0]
  APP_CAR_GENERIC_DATAS.value = getOneVehicleRequest.carGenericDatas[0]
  modalSearchVehicle.value.hide()
  navigateTo("/catalogue/mon-vehicule/default")
}

const getAllBrandsForSearch = async () => {
  allBrands.value = (await $fetch('/api/searchVehicleByBrandsModelType?level=1', {
    headers: {
      'User-Authorization' : `${appStore.auth.userToken}`
    }
  })).responseData.mfrFacets.counts
}

const getModelOfBrands = async (event) => {
  allModel.value = []
  allType.value = []
  levl1_id.value = event.target.value
  allModel.value = (await $fetch(`/api/searchVehicleByBrandsModelType?level=2&level1Id=${levl1_id.value}`, {
    headers: {
      'User-Authorization' : `${appStore.auth.userToken}`
    }
  })).responseData.vehicleModelSeriesFacets.counts
}

const getTypeOfModel = async (event) => {
  levl2_id.value = event.target.value
  allType.value = (await $fetch(`/api/searchVehicleByBrandsModelType?level=3&level1Id=${levl1_id.value}&level2Id=${levl2_id.value}`, {
    headers: {
      'User-Authorization' : `${appStore.auth.userToken}`
    }
  })).responseData.linkageTargets
}

const getSelectedVehicle = (event) => {
  let KType = parseInt(event.target.value)
  vehicleSelectedBrandModelType.value = allType.value.filter(elm => elm.linkageTargetId === KType)[0]
}

const searchVehicleByBrandsModelType = async () => {
  if(vehicleSelectedBrandModelType.value.linkageTargetId) {
    APP_INFO_CAR.value = {
      actualKType: vehicleSelectedBrandModelType.value.linkageTargetId,
      actualImmat: "",
      actualVin: "",
      lastKType: "",
      lastImmat: ""
    }
    APP_CAR_PLATE_DATAS.value = ''
    APP_CAR_GENERIC_DATAS.value = vehicleSelectedBrandModelType.value
    modalSearchVehicle.value.hide()
    location.replace("/catalogue/mon-vehicule/default")
  }
}

</script>

<style lang="scss" scoped>
#div_logo {
  margin-bottom: 1rem;
}

.sidebar {
  height: 100vh;
  width: 285px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f8f9fa;
  // padding: 0px 20px;
  padding-top: 20px;
  overflow: scroll;
}

.sidebar .nav-link {
  padding: 10px 20px;
  color: #333;
}

.sidebar .dropdown-menu {
  background-color: #f8f9fa;
}

.sidebar i {
  font-size: 22px;
}

.list-group a i {
  font-size: 15px;
}

.search-container {
  position: relative;
}

.search-input {
  padding: 8px 30px 8px 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  width: 253px;
  font-family: var(--default-font);
  //text-align: center;
  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    text-align: left;
  }
}

.search-icon,
.fa-xmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.search-icon {
  right: 10px;
  font-size: 16px;
}

.result {
  position: absolute;
  width: 400px;
  z-index: 1;
  background: #fff;
  padding: 1rem;
  border: 1px solid #DDE2E8;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
}

.fa-xmark {
  right: 10px;
  cursor: pointer;
  font-size: 16px;
}

.searchOem {
  cursor: pointer;
}

.baseMenuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 100%;
  box-sizing: border-box;
  transition: background-color var(--animation-site-duration) linear;
  text-decoration: none;

  // &:hover {
  // 	background-color: rgb(var(--mainColor));
  // }
}

.baseMenuButton_mainMenu {
  font-size: var(--sizeFont);
  font-weight: 700;
  font-family: 'inter';
  display: flex;
  flex-direction: column;
  gap: 0.55rem;
  color: rgb(var(--mainText));
}


.accordion-button:focus {
  border: none;
}

.accordion-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}


.sidebar .categorie {
  a {
    text-decoration: none;
    color: rgb(var(--mainText));
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

.list-group-item {
  border: none;
  background-color: transparent;
  padding-left: 2rem;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.accordion-button:not(.collapsed) {
  padding: 0;
  background-color: transparent;
  box-shadow: none;

}

.gap1 {
  gap: 1rem;
  padding: 0 20px;
}

.gap2 {
  gap: 2rem;
  padding: 0 20px;
}

.gap2 li,
.accordion-button {
  font-weight: 600;
  color: rgb(var(--mainText));
}

img.img {
  margin-right: 0.75rem;
}

#navbar_logo {
  width: 50%;
}

@media all and (max-width: 1350px) {
  .baseMenuButton {
    padding: 1.5rem;
  }

  .baseMenuButton_mainMenu {
    font-size: 1.1rem;
  }
}

// .router-link-active,
// .router-link-exact-active {
// 	background-color: rgb(var(--mainColor));
// }
.active {
  position: relative;
}

.active:after {
  //background: var(--shades76);
  content: "";
  width: 115%;
  height: 50px;
  background: var(--shades76);
  top: -12px;
  position: absolute;
  display: inline-block;
  left: -20px;
  z-index: -1;
  border-left: 5px solid var(--mainColor);
}

.activeSubCategory {
  color: var(--mainColor);
}


.box-input {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.btn-linear i {
  margin-right: 10px;
}

.input-vehicule::placeholder {
  text-align: center;
}
.input-vehicule, .input-vehicule::placeholder {
  font-family: Inter;
}

.box-search-immat {
  display: flex;
  flex-direction: column;
}

.box-search-immat h2 {
  line-height: 1;
  margin-bottom: unset;
}

.box-immat,
.box-vin,
.box-search-vehicule {
  flex: 1;
  font-weight: 700;
}

.vin {
  display: flex;
}

.btn-linear {
  padding: 7px 15px;
  margin-right: 15px;
}

.input-with-flag {
  display: flex;
}

.dropdown-toggle {
  background-color: #fff;
  border: none;
  height: 100%;
}

.dropdown-toggle::after {
  color: var(--principal);
}

.form-control,
.dropdown-toggle {
  border-radius: 0;
}

.btn-search {
  margin-left: 15px;
  position: relative;
}

.box-search-by-vehicule .box-vehicule-input {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.box-search-by-vehicule .box-vehicule-input .form-control {
  border-radius: 8px;
}

.by-marque {
  display: flex;
}

.by-marque .marques {
  border-radius: 10px 0 0 10px !important;
}

.by-marque .dropdown-toggle {
  height: auto;
}

input[name="Par marque"] {
  width: 70%;
}

#ByModele {
  margin-left: 5rem;
}

#ByType {
  margin-left: 11rem;
}

input[name="Par modèle"] {
  width: 75%;
}

#dropdownMenuButton1 {
  border-radius: 0.625rem 0rem 0rem 0.625rem;
  border: 1px solid #C7C3C3;
}

#History {
  border-radius: 0rem 0.625rem 0.625rem 0rem;
  border: 1px solid #C7C3C3;
}

.dropdown-toggle::after {
  color: var(--mainColor);
}

.title-after {
  font-size: 1.5rem;
}

#History i {
  color: var(--mainColor);
}

input::placeholder {
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: -0.01rem;
}

.searchByImmat,
.searchByVehicle {
  background: var(--shades76);
  padding: 30px;
  border-radius: 0 0 15px 15px;
}

.tabMenu {
  margin-bottom: -1px;
}

.tabMenuItem {
  padding: 1.0625rem 1.25rem;
  cursor: pointer;
}

.tabMenuItem.activeTabMenuItem {
  background: var(--shades76);
  border-radius: 10px 10px 0 0;
}

.tabMenuItem.activeTabMenuItem .icon-tab {
  border: 1px solid var(--mainColor);
  background: transparent;
}

.tabMenuItem.activeTabMenuItem span {
  color: var(--mainColor);
}

.tabMenuItem span {
  color: var(--secondaryColor);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  /* 90% */
}

.icon-tab {
  padding: 0.6875rem;
  border-radius: 0.25rem;
  background: var(--shades94);
}

.icon-tab i {
  color: var(--mainColor);
  font-size: 1.5rem;
}

.modal-header h5 {
  color: var(--mainColor);
  text-align: center;
  /* Paragraphe Semi Bold */
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.vin input {
  border-radius: 0.625rem 0rem 0rem 0.625rem;
}

.box-immat p,
.box-vin p,
.searchByVehicle p {
  color: var(--neutral23, #3B393D);
  font-feature-settings: 'calt' off;
  /* Body Medium */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  /* 150% */
  letter-spacing: -0.01rem;
}

.w-92 {
  width: 92%;
}

.btnSearchByVehicle {
  padding: 20px !important;
}

.searchVehicleSelect select {
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  border: 1px solid var(--neutral77, #C7C3C3);
  font-family: Inter;
  color: var(--secondaryColor);
}

.searchVehicleSelect select option {
  /* Label Medium */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--secondaryColor)
}

.searchVehicleSelect select option:disabled {
  color: var(--neutral71);
}

#modalValidVehicleLabel {
  color: var(--mainColor);
  font-family: Inter;
}

#modalValidVehicle .dropdown-toggle,
#modalValidVehicle .input-vehicule {
  border: 1px solid var(--mainColor);
  font-family: Inter;
  font-weight: 600;
}

.tableImmatVehicle thead tr th {
  background-color: var(--background97);
  color: var(--neutral45);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.tableImmatVehicle tbody tr td {
  color: var(--neutral45);
  /* Label Regular */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.tableImmatVehicle .type {
  font-weight: 600;
  color: var(--neutral23);
}

.tableImmatVehicle .btn-linear {
  padding: 0.6875rem 1.25rem;
}

.w-10 {
  width: 10%;
}

.logout {
  position: absolute;
  bottom: 0;
  padding: 0 20px;
  width: 100%;
}

.historyVehicleText {
  font-family: Inter;
  cursor: pointer;
  padding: 1rem;
  font-weight: 500;
}

@media all and (min-width: 1350px) {
  .navbar_logo {
    max-width: 110px;
    min-width: 110px;
  }
}

@media all and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

@media all and (width<600px) {
  .navbar {
    // flex-direction: column;
    height: auto;
    padding-top: 1rem;
  }
}
</style>
