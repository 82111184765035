import { watch, onBeforeUnmount, unref } from "vue";
/**
    @function useEventListener
    @param {EventTarget|Ref} target - The DOM element or ref to the DOM element to which the event listener should be attached.
    @param {string} event - The name of the event to listen for.
    @param {function} handler - The function to be called when the event is triggered.
    @returns {void}
    @example useEventListener(buttonRef, "click", () => console.log("Button was clicked"));
**/
export const useEventListener = (target, event, handler) => {
	if (isRef(target)) {
		watch(target, (value, oldValue) => {
			oldValue?.removeEventListener(event, handler);
			value?.addEventListener(event, handler);
		});
	} else {
		target.addEventListener(event, handler);
	}

	onBeforeUnmount(() => {
		unref(target)?.removeEventListener(event, handler);
	});
};
